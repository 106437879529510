angular.module('fingerink')
    .directive('documentImage', function () {
        return {
            restrict: 'A',
            replace: false,
            /*template: '<img/>',*/
            scope: {
                id: '=',
                page: '=', 
                token: '=',
                load: '&'
            },
            controller: 'documentImageCtrl'
        };
    })
    .controller('documentImageCtrl', function ($rootScope, $scope, $element, $attrs, signatureService, $timeout, $interval) {

        var promise;

        $scope.stop = () => $interval.cancel(promise);
        $scope.start = () => {
            promise = $interval(load, 5000);
        };

        $scope.$watch('page', function () {
            if ($scope.id && $scope.page >= 0) {
                load();
            }
        });

        $scope.$on('$destroy', function () {
            $scope.stop();
        });

        function load() {
            $scope.stop();
            let promise;
            if($scope.token){
                promise = signatureService.getTokenURL($scope.token, $scope.id, $scope.page);
            }else{
                promise = signatureService.getURL($scope.id, $scope.page);
            }
            promise.then((response) => {
                if(response.data){
                    $element.attr('src', response.data);
                    $scope.load(response.data);
                }else{
                    $scope.start();
                }
            });

        }
    });